/* --- MOBILE STYLES --- */

.blinking-cursor {
  color: #009e66;
  animation: blink 1s step-start;
}

.landing .name {
  font-size: 48px;
}

.landing .description {
  font-size: 26px;
}

.landingImage {
  right: 0px;
  height: 60%;
  /* margin-bottom: 20px; */
}

.landing .socialIcons {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 20px;
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
    height: 70%;
  }

  .landing .socialIcons {
    flex-direction: column;
  }
}